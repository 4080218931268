* {
  margin: 0;
  padding: 0;
  font-family: Montserrat;
}

footer {
  color: #5e5e5e;
  padding-bottom: 2rem;
  width: -webkit-fill-available;
  display: flex;
  flex-direction: column;
  background-color: rgb(255, 255, 255);
  position: fixed;
  left:0;
  bottom:0;
  right:0;
}

.footerBody {
  position: relative;
  position: absolute; bottom: 0; width: 100%
}

/* Bottom - copyright and legal stuff */

footer .info {
  padding: 1rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

footer .info .legal {
  display: flex;
  flex-wrap: nowrap;
}

footer .info .legal > a {
  margin-right: 15px;
  color: #5e5e5e;
}

@media screen and (max-width: 600px) {  
  footer .info {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: flex-end;
    justify-content: space-around;
  }

  footer .info > .copyright {
    margin-top: 10px;
  }
}

@media screen and (max-width: 380px) {  
  footer .info .legal {
    justify-content: center;
  }

  footer .info .legal a {
    margin: 0;
    text-align: center;
  }
}
