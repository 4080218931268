.news-article-box {
    border: 2.5px;
    border-style: solid;
    border-radius: 0.5rem;
    border-color: black;

    /* width: 80%; */
    height: 250px;
    margin: 50px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: left;
}

.news-box-title {
    font-family: Montserrat;
    font-size: 1.5rem;
    font-weight: bold;
    margin: 20px;
}

.news-box-subtitle {
    font-family: Montserrat;
    font-size: 1rem;
    font-weight: 550;
    margin: 20px;
}

.news-content-box {
    border: 2.5px;
    border-style: solid;
    border-radius: 0.5rem;
    border-color: black;

    /* width: 80%; */
    height: fit-content;
    margin: 50px;
    box-shadow: #00000058 0rem 0rem 20px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: left;
    max-width: 1220px;

    margin-bottom: 10rem;
}

.news-content-subtitle {
    font-family: Montserrat;
    font-size: 1.08rem;
    font-weight: normal;
    margin: 20px;
    margin-inline: 10rem;
}

.news-content-text {
    font-family: Montserrat;
    font-size: 1.08rem;
    font-weight: normal;
    margin: 20px;
    margin-inline: 10rem;
    text-align: left;
}

.news-content-text-bold{
    font-family: Montserrat;
    font-size: 1.08rem;
    font-weight: 600;
}

.news-content-title {
    font-family: Montserrat;
    font-size: 1.5rem;
    font-weight: bold;
    margin: 20px;
}

@media only screen and (max-width: 768px) {
    .news-content-text {
        font-family: Montserrat;
        font-size: 1.08rem;
        font-weight: normal;
        margin: 20px;
        margin-inline: 2rem;
        text-align: left;
    }

    .news-content-subtitle {
        font-family: Montserrat;
        font-size: 1.08rem;
        font-weight: normal;
        margin: 20px;
        margin-inline: 2rem;
    }

    .news-content-title {
        text-align: center;
    }
}



/* News Button Styling */
.news-btn {
    appearance: none;
    background-color: #000000;
    border: 2px solid #1A1A1A;
    border-radius: 15px;
    box-sizing: border-box;
    color: #FFFFFF;
    cursor: pointer;
    display: inline-block;
    font-family: Roobert,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    margin: 0;
    min-height: 60px;
    min-width: 0;
    outline: none;
    padding: 16px 24px;
    text-align: center;
    text-decoration: none;
    transition: all .25s ease;
}

.news-btn:hover {
    box-shadow: rgb(0, 255, 251) 0 8px 15px;
    color: aqua;

    transition: all .25s ease;
}