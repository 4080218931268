@media only screen and (max-width: 768px){

    article#one {
        width: 80%;
        padding: 0 49px 20px;
    }

    .container2 {
        padding: 0 49px 20px;
    }

    article#three {
        padding-bottom: 10rem;
    }
}

#patch_notes_container_title {
    padding-bottom: 4.5rem;
}

.changes-text {
    font-size: 1.2rem;
}

.bugs_title {
    font-size: 1.2rem;
}

.changes_title {
    font-size: 1.2rem;
}

.patch_notes_summary{
    font-size: 1.2rem;
}